import React from 'react';

import { ContentAnimate } from '../components/ContentAnimate/ContentAnimate';
import Layout from '../components/layout';
import SEO from '../components/seo';
import HeroSignatureIcon from '../images/Index/hero-signature';

import style from '../styles/pages/privacy-policy.scss';

const PrivacyPolicyPage = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <section className={style.hero}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2">
              <h1>Privacy Policy</h1>
              <ContentAnimate inViewAnimationClass={style.heroInView}>
                <HeroSignatureIcon className={style.signatureIcon} />
              </ContentAnimate>
              <p>
                Trend Capital Holdings, Inc ("Company" or "We") respects your privacy and is
                committed to protecting it through our compliance with this policy.
              </p>
              <p>
                This policy describes the types of information we may collect from you or that you
                may provide when you visit the website trendcapital.com (our "Website") and our
                practices for collecting, using, maintaining, protecting, and disclosing that
                information.
              </p>
              <p>
                This Website is for use by persons who live in the United States. We do not warrant
                that this Website is appropriate or authorized for use in any other country. If you
                live outside of the United States, you are responsible for determining whether our
                Privacy Policy and the rest of this Website comply with the laws that apply to you.
                If our Privacy Policy and the rest of this Website do not comply with the laws that
                apply to you, you must not access or use this Website.
              </p>
              <p>This policy applies to information we collect:</p>
              <ul>
                <li>On this Website.</li>
                <li>
                  In e-mail, text, and other electronic messages between you and this Website.
                </li>
                <li>
                  When you interact with our advertising and applications on third-party websites
                  and services, if those applications or advertising include links to this policy.
                </li>
              </ul>
              <p>It does not apply to information collected by:</p>
              <ul>
                <li>
                  us offline or through any other means, including on any other website operated by
                  Company or any third party (including our affiliates and subsidiaries); or
                </li>
                <li>
                  any third party (including our affiliates and subsidiaries), including through any
                  application or content (including advertising) that may link to or be accessible
                  from or on the Website.Please read this policy carefully to understand our
                  policies and practices regarding your information and how we will treat it. If you
                  do not agree with our policies and practices, your option is not to use our
                  Website. By accessing or using this Website, you agree to this privacy policy.
                  This policy may change from time to time. If we decide to change this Privacy
                  Policy, we will post those changes on our Website. Your continued use of this
                  Website after we make changes is deemed to be acceptance of those changes, so
                  please check the policy periodically for updates.
                </li>
              </ul>
              <h5>The kinds of Information We Collect About You, and How We Collect It</h5>
              <p>
                We collect several types of information from and about users of our Website,
                including information:
              </p>
              <ul>
                <li>
                  by which you may be personally identified, such as name and email address, and
                  other contact information ("personal information");
                </li>
                <li>
                  that is about you but individually does not identify you, such as your IP address,
                  the type of browser you are using, the times you access our website, and the
                  website that referred you to our Website.
                </li>
              </ul>
              <p>We collect this information:</p>
              <ul>
                <li>Directly from you when you provide it to us.</li>
                <li>
                  Automatically as you navigate through the site. Information collected
                  automatically may include usage details, IP addresses and information collected
                  through cookies, web beacons, and other tracking technologies.
                </li>
                <li>From third parties, for example, our business partners.</li>
              </ul>
              <h5>Information You Provide to Us</h5>
              <p>The information we collect on or through our Website may include:</p>
              <ul>
                <li>
                  Information that you provide by filling in forms on our Website to contact us. We
                  may also ask you for information when you report a problem with our Website.
                </li>
                <li>
                  Records and copies of your correspondence (including e-mail addresses), if you
                  contact us.
                </li>
                <li>
                  Your responses to surveys that we might ask you to complete for research purposes.
                </li>
              </ul>
              <h5>Information We Collect Through Automatic Data Collection Technologies</h5>
              <p>
                As you navigate through and interact with our Website, we may use automatic data
                collection technologies to collect certain information about your equipment,
                browsing actions and patterns, including:
              </p>
              <ul>
                <li>
                  Details of your visits to our Website, including traffic data, location data,
                  logs, and other communication data and the resources that you access and use on
                  the Website.
                </li>
                <li>
                  Information about your computer and internet connection, including your IP
                  address, operating system, and browser type.
                </li>
              </ul>
              <p>
                The information we collect automatically may include personal information, and we
                may maintain it or associate it with personal information we collect in other ways
                or receive from third parties. It helps us to improve our Website and to deliver a
                better and more personalized service, including by enabling us to:
              </p>
              <ul>
                <li>Estimate our audience size and usage patterns.</li>
                <li>
                  Store information about your preferences, allowing us to customize our Website
                  according to your individual interests.
                </li>
                <li>Speed up your searches.</li>
                <li>Recognize you when you return to our Website.</li>
              </ul>
              <p>The technologies we use for this automatic data collection may include:</p>
              <ul>
                <li>
                  Cookies (or browser cookies). A cookie is a small file placed on the hard drive of
                  your computer. You may refuse to accept browser cookies by activating the
                  appropriate setting on your browser. However, if you select this setting you will
                  not be able to use the Website.
                </li>
              </ul>
              <h5>What are Analytics and how do we use them?</h5>
              <p>
                Visitors to our Website use different operating systems and mobile devices. To make
                your visits as easy as possible with the technology you use, we may use Analytics
                Tools such as Google Analytics that automatically keep track of information like the
                operating system (e.g.,Windows, MacOS iOS, Android) you use, your IP address, domain
                name of your Internet service provider, websites visited before and after this
                Website, resources used, pages viewed and activities on our Website, and
                advertisements seen or clicked on. We also track the total number of users of our
                Websites in an aggregate form to allow us to update and improve them.
              </p>
              <p>
                To learn more about Google Analytics, see "How Google uses data when you use our
                partners' sites or apps" at www.google.com/policies/privacy/partners.
              </p>
              <h5>How We Use Your Information</h5>
              <p>
                We use information that we collect about you or that you provide to us, including
                any personal information:
              </p>
              <ul>
                <li>To present our Website and its contents to you.</li>
                <li>
                  To contact you via telephone, email, or text message unless you choose to opt out
                  of one or all of these communication methods.
                </li>
                <li>To fulfill any other purpose for which you provide it.</li>
                <li>To allow you to participate in interactive features on our Website.</li>
                <li>In any other way we may describe when you provide the information.</li>
                <li>
                  For any other purpose with your consent.We may use the information we have
                  collected from you to enable us to display advertisements to our advertisers'
                  target audiences. Even though we do not disclose your personal information for
                  these purposes without your consent, if you click on or otherwise interact with an
                  advertisement, the advertiser may assume that you meet its target criteria.
                </li>
              </ul>
              <h5>Disclosure of Your Information</h5>
              <p>
                As a general rule, Trend Capital will not sell, share, or rent your personal
                information. However, we may disclose aggregated information about our users, and
                information that does not identify any individual, without restriction.
              </p>
              <p>
                We may disclose personal information that we collect or you provide as described in
                this privacy policy:
              </p>
              <ul>
                <li>
                  To a buyer or other successor in the event of a merger, divestiture,
                  restructuring, reorganization, dissolution or other sale or transfer of some or
                  all of Trend Capital's assets, whether as a going concern or as part of
                  bankruptcy, liquidation, or similar proceeding, in which personal information held
                  by Trend Capital about our Website users is among the assets transferred.
                </li>
                <li>To fulfill the purpose for which you provide it.</li>
                <li>For any other purpose disclosed by us when you provide the information.</li>
                <li>With your consent.</li>
              </ul>
              <p>We may also disclose your personal information:</p>
              <ul>
                <li>
                  To comply with any court order, law, or legal process, including to respond to any
                  government or regulatory request.
                </li>
                <li>
                  If we believe disclosure is necessary or appropriate to protect the rights,
                  property, or safety of Trend Capital, our customers, or others. This includes
                  exchanging information with other companies and organizations for the purposes of
                  fraud protection and credit risk reduction.
                </li>
              </ul>
              <h5>Choices About How We Use and Disclose Your Information</h5>
              <p>
                We strive to provide you with choices regarding the personal information you provide
                to us. We have created mechanisms to provide you with the following control over
                your information:
              </p>
              <ul>
                <li>
                  Tracking Technologies and Advertising. The "help" function of your browser should
                  contain instructions on how to set your browser to not accept new cookies, to
                  notify you when a cookie is issued, or how to disable cookies altogether. If you
                  disable or refuse cookies, please note that some parts of this site will be
                  inaccessible or not function properly.
                </li>
              </ul>
              <h5>Children Under the Age of 18</h5>
              <p>
                Our Website is not intended for children under 18 years of age. No one under age 18
                may provide any information to or on the Website. We do not knowingly collect
                personal information from children under 18. If you are under 18, do not use or
                provide any information on this Website. If we learn we have collected or received
                personal information from a child under 18 without verification of parental consent,
                we will delete that information. If you believe we might have any information from
                or about a child under 18, please contact us at{' '}
                <a href="mailto:hello@trendcapital.com">hello@trendcapital.com</a>.
              </p>
              <h5>COMPLIANCE WITH DO-NOT-CALL REGISTRY, and SMS usage</h5>
              <p>
                When you submit your personal information through trendcapital.com, you are
                providing your express consent to be contacted via email, telephone, postal mail, or
                text message.
              </p>
              <h5>Your California Privacy Rights</h5>
              <p>
                California Civil Code Section § 1798.83 permits users of our Website that are
                California residents to request certain information regarding our disclosure of
                personal information to third parties for their direct marketing purposes. To make
                such a request, please send an email to{' '}
                <a href="mailto:hello@trendcapital.com">hello@trendcapital.com</a>. Please note that
                this opt-out does not prohibit disclosure made for non-marketing purposes.
              </p>
              <p>
                California law also permits residents of California to request and obtain from us
                once per year, free of charge, a list of the third parties (if any) to whom we have
                disclosed personal information for their direct marketing purposes in the prior
                calendar year, as well as the type of personal information disclosed to those
                parties. If you are a California resident and would like to request this
                information, please submit your request in an e-mail to{' '}
                <a href="mailto:hello@trendcapital.com">hello@trendcapital.com</a>. Requests via
                telephone or facsimile will not be accepted. The e-mail subject line and the content
                of your request must include the phrase "Your California Privacy Rights," and
                include your name, e-mail address (if you wish to receive a response via e-mail) or
                street address, city, state, and zip code (if you wish to receive a response via
                postal mail). Please allow 30 days to receive a response to your inquiry.
              </p>
              <h5>Data Security</h5>
              <p>
                We have implemented reasonable measures designed to secure your personal information
                from unauthorized access, use, alteration, and disclosure.
              </p>
              <p>
                Unfortunately, the transmission of information via the internet is not completely
                secure. Although we do our best to protect your personal information, we cannot
                guarantee the security of your personal information transmitted to our Website. Any
                transmission of personal information is at your own risk. We are not responsible for
                circumvention of any privacy settings or security measures contained on the Website.
              </p>
              <h5>Third party websites</h5>
              <p>
                The Website may contain links to third-party websites, including Facebook and
                Twitter, which may have privacy policies that differ from our own. We are not
                responsible for, nor do we endorse, any of the activities and practices that take
                place on these sites. Accordingly, we recommend that you review the privacy policy
                posted on any external site before disclosing any personal information. Please
                contact those websites directly if you have any questions about their privacy
                policies.
              </p>
              <h5>Agreement to this Policy; Changes to Our Privacy Policy</h5>
              <p>
                By accessing, visiting and/or using this Website, you, the visitor, acknowledge that
                you have read, understand and agree to be bound by this Policy. If you do not agree
                to be bound by this Policy, do not use this Website. Trend Capital reserves the
                right to change this Policy at any time by posting an updated copy of this Policy on
                this Website. You will be bound by those changes if you visit this Website after
                those changes have been posted. If we make material changes to how we treat our
                users' personal information, we will notify you through a notice on the Website home
                page. The date this Policy was last modified is identified at the beginning of this
                Policy. Please check this Policy periodically for changes.
              </p>
              <h5>Contact Information</h5>
              <p>
                To ask questions or comment about this privacy policy and our privacy practices,
                contact us at <a href="mailto:hello@trendcapital.com">hello@trendcapital.com</a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PrivacyPolicyPage;
