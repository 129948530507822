import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const HeroSignature = (props: IIconProps) => {
  const { width = 311, height = 72, className } = props;

  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 311 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-21 71C7.897 45.917 70.244-2.15 88.458 6.25c22.767 10.5-50.789 58.625-15.762 35C100.717 22.35 160.262 6.542 186.532 1c-19.703 17.063-59.02 35.7-28.897 17.5C195.288-4.25 300.368 6.25 310 6.25"
        stroke="#000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default React.memo(HeroSignature);
